// const { ids } = require("webpack");







let checkSelectvalidate = function(select) {
    let error = "error" + select.getAttribute("id");
    if (document.querySelector("#" + error)) { document.querySelector("#" + error).remove(); }
    if (select.value != "0") {
        select.parentNode.classList.add("is--valid");
        select.parentNode.classList.remove("is--invalid");
        select.removeAttribute("aria-describedby");
    } else {
        select.parentNode.classList.remove("is--valid");
        select.parentNode.classList.add("is--invalid");
        select.setAttribute("aria-describedby", error);
        let elError = document.createElement("span");
        elError.setAttribute("id", error);
        elError.classList.add("form__input--error");
        elError.innerHTML = "Wybierz jedną z opcji";
        select.parentNode.after(elError);
    }
}


let checkInputValidate = function(input) {

    let error = "error" + input.getAttribute("id");
    if (input.parentNode.querySelector(".form__input--error")) { input.parentNode.querySelector(".form__input--error").remove(); }
    if (input.validity.valid) {
        input.parentNode.classList.add("is--valid");
        input.parentNode.classList.remove("is--invalid");

        input.removeAttribute("aria-describedby");
    } else {
        input.parentNode.classList.remove("is--valid");
        if (input.form.classList.contains("afterFirstValidate")) {
            input.parentNode.classList.add("is--invalid");
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.parentNode.append(elError);
        }
    }
}




let checkFileValidate = function(input) {

    let error = "error" + input.getAttribute("id");
    if (input.parentNode.querySelector(".form__input--error")) { input.parentNode.querySelector(".form__input--error").remove(); }
    if (input.validity.valid) {
        input.parentNode.classList.add("is--valid");
        input.parentNode.classList.remove("is--invalid");
        input.removeAttribute("aria-describedby");
    } else {
        input.parentNode.classList.remove("is--valid");
        if (input.form.classList.contains("afterFirstValidate")) {
            input.parentNode.classList.add("is--invalid");
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.parentNode.append(elError);
        }
    }
}



let checkBoxValidate = function(input) {
    let error = "error" + input.getAttribute("id");
    if (input.form.classList.contains("afterFirstValidate")) {
        if (input.parentNode.querySelector(".form__input--error")) { input.parentNode.querySelector(".form__input--error").remove(); }
        if (input.validity.valid) {
            input.parentNode.classList.add("is--valid");
            input.parentNode.classList.remove("is--invalid")
            input.removeAttribute("aria-describedby");
        } else {
            input.parentNode.classList.remove("is--valid");
            input.parentNode.classList.add("is--invalid")
            input.setAttribute("aria-describedby", error);
            let elError = document.createElement("span");
            elError.setAttribute("id", error);
            elError.classList.add("form__input--error");
            elError.innerHTML = input.validationMessage;
            input.parentNode.prepend(elError);
        }
    }
}

let isEmpty = function(el) {

    if (el.value == "") {
        el.parentNode.classList.add("empty");
        el.parentNode.classList.remove("noempty");
    } else {
        el.parentNode.classList.remove("empty")
        el.parentNode.classList.add("noempty")
        if (!el.validity.valid) {
            el.parentNode.classList.add("noempty");
            el.parentNode.classList.remove("empty");
        }
    }
}


// $('.form__input textarea, .form__input input').bind(' keyup change', function() {
//     checkInputValidate($(this));
//     isEmpty($(this));
//     $(".form-invalid-info").css("display", "none");
// });


if (document.querySelector('select:required')) {
    let select = document.querySelectorAll("select:required");
    for (i = 0; i < select.length; ++i) {
        select[i].addEventListener('change', function(e) {
            (this.value == "0") ? this.classList.remove("js-selected"): this.classList.add("js-selected");
            checkSelectvalidate(this);

        });
    }
}

let inputsRequiredAll = document.querySelectorAll('.form__input textarea:required, .form__input input:required');
let inputsAll = document.querySelectorAll('.form__input textarea, .form__input input');
let inputsCheckBoxAll = document.querySelectorAll('.form__input textarea, .form__checkbox input');
let inputsFileAll = document.querySelectorAll('.form__file input');



if (inputsFileAll) {
    for (i = 0; i < inputsFileAll.length; ++i) {
        inputsFileAll[i].addEventListener("input", function() {
            checkFileValidate(this);
        })
    }
}
if (inputsRequiredAll) {
    for (i = 0; i < inputsRequiredAll.length; ++i) {
        inputsRequiredAll[i].addEventListener("input", function() {
            checkInputValidate(this);
        })
    }
}
if (inputsAll) {
    for (i = 0; i < inputsAll.length; ++i) {
        inputsAll[i].addEventListener("input", function() {
            isEmpty(this)
        });
    }
}

if (inputsCheckBoxAll) {
    for (i = 0; i < inputsCheckBoxAll.length; ++i) {
        inputsCheckBoxAll[i].addEventListener("input", function() {
            checkBoxValidate(this);
        });
    }
}

// $("form input[type='checkbox'][required]").each(function() {
//     $(this).siblings("label").prepend("<span class='text--red'>* </span>");
// })



// function isCaptchaChecked() {
//     return grecaptcha && grecaptcha.getResponse().length !== 0;
// }




let formValidate = function(form, namefunction) {

    const $form = form;
    const $btnSubmitt = form.querySelector('.btn--submit');


    $btnSubmitt.addEventListener('click', function(e) {
        const inputsRequired = form.querySelectorAll(".form__input input:required, .form__input textarea:required");

        const checkBoxRequired = form.querySelectorAll(".form__checkbox input:required");
        const selectRequired = form.querySelectorAll(".form__select select:required");
        const fileRequired = form.querySelectorAll(".form__file input:required");

        e.preventDefault();
        $form.classList.add("afterFirstValidate");


        for (i = 0; i < inputsRequired.length; ++i) {
            checkInputValidate(inputsRequired[i]);

        }
        for (i = 0; i < checkBoxRequired.length; ++i) {
            checkBoxValidate(checkBoxRequired[i]);
        }
        for (i = 0; i < selectRequired.length; ++i) {
            checkSelectvalidate(selectRequired[i]);
        }
        for (i = 0; i < fileRequired.length; ++i) {
            checkFileValidate(selectRequired[i]);
        }

        if (document.querySelectorAll(".is--invalid").length == 0) {

            if (namefunction == 1) {
                form.submit();

            } else {
                e.preventDefault();
                namefunction(form);
            }

        } else {

            if (form.querySelector(".is--invalid")) {
              
                let firstInvalidEl = form.querySelector(".is--invalid");
                if (firstInvalidEl.querySelector("input")) {
                    firstInvalidEl.querySelector("input").focus();
                } else {
                    if (firstInvalidEl.querySelector("textarea")) {
                        firstInvalidEl.querySelector("textarea").focus();
                    } else {
                        if (firstInvalidEl.querySelector("select")) {
                            firstInvalidEl.querySelector("select").focus();
                        }

                    }
                }

            }

        }
    })
}


// $(".reguired--star input:not([type='checkbox'],[type='radio']):required").each(function() {
//     $(this).parent().addClass("input--star");
// })




if (document.querySelector('.form--validate')) {
    let formToValidate = document.querySelectorAll(".form--validate");
    for (i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], 1);


    }
}



var http_request = false;

function makeRequest(url = '/a/', method = 'GET', form, callbackF) {

    http_request = false;

    if (window.XMLHttpRequest) { // Mozilla, Safari,...
        http_request = new XMLHttpRequest();
        if (http_request.overrideMimeType) {
            http_request.overrideMimeType('text/xml');
            // Przeczytaj o tym wierszu poniżej
        }
    } else if (window.ActiveXObject) { // IE
        try {
            http_request = new ActiveXObject("Msxml2.XMLHTTP");
        } catch (e) {
            try {
                http_request = new ActiveXObject("Microsoft.XMLHTTP");
            } catch (e) {}
        }
    }

    if (!http_request) {
        alert('Nie mogę stworzyć instancji obiektu XMLHTTP');
        return false;
    }
    let dataForm = new FormData(form);
    http_request.onreadystatechange = function() { alertContents(http_request, newsletterCallbackFunction) };
    http_request.open(method, url, true);
    http_request.send(dataForm);

}


function alertContents(http_request, functionName) {
    try {
        if (http_request.readyState == 4) {
            if (http_request.status == 200) {
         
                functionName(http_request.responseText);

            } else {
                alert('Wystąpił problem z tym żądaniem.');
            }
        }
    } catch (e) {
        alert('Złapany wyjątek: ' + e.description);
    }
}


function newsletterCallbackFunction(data) {

    let dataJ = JSON.parse(data);

    let info = document.createElement("div");
    info.classList.add("info");
    info.innerHTML = dataJ.komunikat;
    if (dataJ.status) {
        info.classList.add("info--true");

    } else {
        info.classList.add("info--false");
    }
    document.querySelector('.form--newsletter').before(info);
    document.querySelector('.form--newsletter').style.display = "none";
}

function newsletterForm(form) {

    makeRequest('/a/', "POST", form, newsletterCallbackFunction);
}

if (document.querySelector('.form--newsletter')) {
    let formToValidate = document.querySelectorAll('.form--newsletter');
    for (i = 0; i < formToValidate.length; ++i) {
        formValidate(formToValidate[i], newsletterForm);
    }
}



if (document.querySelector('.select-onsubmit')) {
    let selectOS = document.querySelectorAll(".select-onsubmit");
    for (i = 0; i < selectOS.length; ++i) {      
        selectOS[i].addEventListener('change', function(e) {            
            this.form.submit();
        });
    }
}

// if ($("textarea").length) {
//     let textarea = document.querySelector('textarea');

//     textarea.addEventListener('keydown', autosize);

//     function autosize() {
//         var el = this;
//         setTimeout(function() {
//             el.style.cssText = 'height:auto; padding:4px';
//             // for box-sizing other than "content-box" use:
//             // el.style.cssText = '-moz-box-sizing:content-box';
//             el.style.cssText = 'height:' + el.scrollHeight + 'px';
//         }, 0);
//     }
// }


